import moment from "moment";

export const formatDate = (dateString) => {
    // Parse the date string using Moment.js
    let parsedDate = moment(dateString);

    // Format the date as per your requirement
    let formattedDate = parsedDate.format("MMMM Do YYYY, h:mm:ss a"); // Example format

    return formattedDate;
};

export const dateWithDay = (dateString) => {
    const date = moment(dateString);

    // Format the date to include day of the week and date
    const formattedDate = date.format('dddd, Do MMMM YYYY');

    return formattedDate;
}

export function calculateDurationInDays(startDateString, endDateString) {
    // Parse the start and end dates
    const startDate = moment(startDateString);
    const endDate = moment(endDateString);

    // Calculate the difference in days
    const durationInDays = endDate.diff(startDate, 'days');

    return durationInDays;
}