export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem("current_user", JSON.stringify(user));
    } else {
      localStorage.removeItem("current_user");
    }
  } catch (error) {
    console.error("error", error);
  }
};

export const getCurrentUserFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("current_user"));
};
