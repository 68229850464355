import { useFormik } from "formik";
import Cookies from "js-cookie";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { postRequest } from "../../../helper/axiosClient";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";
import { FORGOT_PASSWORD } from "../../constant/apiEndPoint";
import basestyle from "../Base.module.css";
import loginstyle from "./Login.module.css";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const { confirmPassword, ...data } = values; // exclude confirmPassword from the data to be sent
      try {
        const res = await postRequest(FORGOT_PASSWORD, data);
        if (res.type === 1) {
          Cookies.set("token", res?.response?.data?.token);
          successNotification(res?.response?.data?.message);
          navigate("/");
        }
        if (res.type === 0) {
          errorNotification(res.errormessage);
        }
      } catch (error) {
        console.error("res====>", error);
      }
    },
  });

  return (
    <div className="form-bg">
      <div className={loginstyle.login}>
        <form onSubmit={formik.handleSubmit}>
          <h1>Forgot Password</h1>
          <input
            type="password"
            name="password"
            id="password"
            placeholder="Password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
          />
          {formik.touched.password && formik.errors.password ? (
            <p className={basestyle.error}>{formik.errors.password}</p>
          ) : null}
          <input
            type="password"
            name="confirmPassword"
            id="confirmPassword"
            placeholder="Confirm Password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <p className={basestyle.error}>{formik.errors.confirmPassword}</p>
          ) : null}
          <button className={basestyle.button_common} type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
