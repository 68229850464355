import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Footer from '../commonPages/Footer';

const Layout = () => {

    const [isVisible, setIsVisible] = useState(false);

    // const handleclosePopup = () => {
    //     document.body.classList.remove('nav-expanded');
    // };

    const handleScroll = () => {
        if (window.scrollY > 150) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // const scrollToTop = () => {
    //     window.scrollTo({
    //         top: 0,
    //         behavior: 'smooth', // For smooth scrolling
    //     });
    // };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <Outlet />
        </>
    );
};

export default Layout;
