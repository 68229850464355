import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useEffect, useRef } from 'react';
import SplitType from 'split-type';
// import $ from 'jquery';
// import 'jquery.mb.YTPlayer';

gsap.registerPlugin(ScrollTrigger);

const handleLinkClick = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

const heroStyle = {
    position: 'relative',
    overflow: 'hidden',
};

const videoBackgroundStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
};

const iframeStyle = {
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    top: 0,
    left: 0,
    border: 'none',
    pointerEvents: 'none',
};

const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
    zIndex: 1, // Ensure it is above the video but below the content
};

const contentStyle = {
    position: 'relative',
    zIndex: 2, // Ensure content is above the overlay
};

function Banner() {

    const container = useRef(null);

    useEffect(() => {
        if (container.current) {
            let revealContainers = container.current.querySelectorAll(".reveal");
            revealContainers.forEach((container) => {
                let image = container.querySelector("img");
                let tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: container,
                        toggleActions: "play none none none"
                    }
                });
                tl.set(container, { autoAlpha: 1 });
                tl.from(container, 1, { xPercent: -100, ease: "power2.out" });
                tl.from(image, 1, { xPercent: 100, scale: 1, delay: -1, ease: "power2.out" });
            });

            let animatedTextElements = container.current.querySelectorAll('.text-anime-style-3');

            animatedTextElements.forEach((element) => {
                if (element.animation) {
                    element.animation.progress(1).kill();
                }

                let split = new SplitType(element, { types: 'lines,words,chars' });
                gsap.set(element, { perspective: 400 });

                gsap.set(split.chars, {
                    opacity: 0,
                    x: "50",
                });

                element.animation = gsap.to(split.chars, {
                    scrollTrigger: { trigger: element, start: "top 90%" },
                    x: "0",
                    y: "0",
                    rotateX: "0",
                    opacity: 1,
                    duration: 1,
                    ease: "back.out",
                    stagger: 0.02,
                });
            });

            let animatedTextElements1 = container.current.querySelectorAll('.text-anime-style-4');

            animatedTextElements1.forEach((element) => {
                if (element.animation) {
                    element.animation.progress(1).kill();
                }

                let split = new SplitType(element, { types: 'lines,words,chars' });
                gsap.set(element, { perspective: 400 });

                gsap.set(split.chars, {
                    opacity: 0,
                    x: "50",
                });

                element.animation = gsap.to(split.chars, {
                    scrollTrigger: { trigger: element, start: "top 85%" },
                    x: "0",
                    y: "0",
                    rotateX: "0",
                    opacity: 1,
                    duration: 1,
                    ease: "back.out",
                    stagger: 0.02,
                });
            });
        }
        // $('#herovideo').YTPlayer({
        //     videoURL: 'TzfnlPxCZv0',
        //     containment: '.hero-video',
        //     showControls: false,
        //     autoPlay: true,
        //     loop: true,
        //     vol: 0,
        //     mute: false,
        //     startAt: 0,
        //     stopAt: 296,
        //     opacity: 1,
        //     addRaster: true,
        //     quality: 'large',
        //     optimizeDisplay: true
        // });
    }, []);

    return (
        <>
            {/* Hero Layout 2 Section Start */}
            <div className='main-banner'>
                <section
                    className="wrapper image-wrapper bg-image bg-overlay bg-overlay-300"
                    data-image-src="assets/img/photos/bg16.png"
                >
                    <div className="hero-layout2 hero-video pb-0" ref={container}>
                        <div className="hero-bg-video">
                            {/* <div
                                id="herovideo"
                                className="player"
                                data-property="{videoURL:'ItfarvW2ESU',containment:'.hero-video', showControls:false, autoPlay:true, loop:true, vol:0, mute:false, startAt:0,  stopAt:296, opacity:1, addRaster:true, quality:'large', optimizeDisplay:true}"
                            /> */}
                            {/* <source src="/Videos/video1.mp4" type="video/mp4"/> */}
                        </div>
                        <div className="container banner-content" style={contentStyle}>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    {/* Hero Content Start */}
                                    <div className="hero-content">
                                        {/* Section Title Start */}
                                        <div className="section-title">
                                            <h3 className="wow fadeInUp">Premier Academy</h3>
                                            <h1 className="text-anime-style-3 mb-2" style={{ fontWeight: '400' }}>
                                                Welcome to
                                            </h1>

                                            <a className="navbar-brand wow fadeInUp" href="/">
                                                <img src="assets/logo/Sage_Academy_Main_Long-removebg-preview.png" alt="Logo" />
                                            </a>
                                        </div>
                                        {/* Section Title End */}
                                        {/* Hero Body Start */}
                                        {/* <div className="hero-body">
                                            <p className="wow fadeInUp banner-desc">
                                                Where dental excellence meets educational innovation. As a
                                                premier Dental Academy, we are dedicated to
                                                shaping the future of dentistry by nurturing the
                                                next generation of skilled professionals and
                                                providing crucial education and training.
                                            </p>
                                        </div> */}
                                    </div>
                                    {/* Hero Content Start */}
                                </div>
                                <div className="col-lg-6">
                                    {/* Hero Image Start */}
                                    {/* <div className="hero-images">
                                        <div className="hero-image-1">
                                            <figure className="image-anime reveal">
                                                <img src="assets/images/hero-img-1.jpg" alt="" />
                                            </figure>
                                        </div>
                                        <div className="hero-image-2">
                                            <figure className="image-anime reveal">
                                                <img src="assets/images/hero-img-2.jpg" alt="" />
                                            </figure>
                                        </div>
                                    </div> */}
                                    {/* Hero Image End */}
                                    <p className="wow fadeInUp banner-desc text-center">
                                        "Where excellence meets educational innovation. As a premier education academy, we are dedicated to advancing your career by nurturing the next generation of skilled professionals."
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div id="whoweare" style={{ marginBottom: '50px' }}></div>
                        {/* /.container */}
                        <div className="overflow-hidden">
                            <div className="divider  mx-n2" style={{ color: '#FFF' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 60">
                                    <path fill="currentColor" d="M0,0V60H1440V0A5771,5771,0,0,1,0,0Z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </section>
                {/* /section */}
                <section className="wrapper">
                    <div className="container pb-15 pb-md-17 nik">
                        <div className="row gx-md-5 gy-5 mb-14 mb-md-17">
                            <div className="section-title" style={{ marginBottom: '0px', textAlign: 'center' }}>
                                {/* <h3 className="wow fadeInUp">our goal</h3> */}
                                <h2 className="text-anime-style-4">
                                    Who we are
                                </h2>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className="why-choose-item wow fadeInUp why-choose-item-visible who-we-are-card" data-wow-delay="0.25s">
                                    {/* <div className="why-choose-image">
                                        <img src="assets/images/cultuer-1.jpg" alt="" />
                                    </div> */}

                                    <div className="why-choose-content">
                                        <p className='text-justify'>SAGE Academy focuses on providing essential education and advanced training that equips you with the knowledge and expertise necessary to thrive in your field.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className="why-choose-item wow fadeInUp why-choose-item-visible  who-we-are-card" data-wow-delay="0.25s">
                                    {/* <div className="why-choose-image">
                                        <img src="assets/images/cultuer-2.jpg" alt="" />
                                    </div> */}

                                    <div className="why-choose-content">
                                        <p className='text-justify'>At SAGE, our CPD programs are tailored to your needs and goals.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className="why-choose-item wow fadeInUp why-choose-item-visible  who-we-are-card" data-wow-delay="0.25s">
                                    {/* <div className="why-choose-image">
                                        <img src="assets/images/cultuer-3.jpg" alt="" />
                                    </div> */}
                                    <div className="why-choose-content">
                                        <p className='text-justify'>SAGE creates a dynamic learning environment that balances technical expertise with ethical responsibility and compassion.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className="why-choose-item wow fadeInUp why-choose-item-visible  who-we-are-card" data-wow-delay="0.25s">
                                    {/* <div className="why-choose-image">
                                        <img src="assets/images/cultuer-4.jpg" alt="" />
                                    </div> */}
                                    <div className="why-choose-content">
                                        <p className='text-justify'>Join us at SAGE Academy and embark on a journey of continuous learning, growth, and advancement in the dynamic field of dentistry. Unlock your full potential and take your career to new heights with SAGE.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /.container */}
                </section>
                {/* /section */}
            </div>
        </>
    );
}

export default Banner;
