import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loader = () => {
  return (
    <div className='d-flex  justify-content-center align-items-center' style={{ minHeight: "300px" }}>
      <Spinner animation="grow" style={{ background: "#F0BD6C" }} />
    </div>
  )
}

export default Loader;
