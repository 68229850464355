import { useFormik } from "formik";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { postRequest } from "../../../helper/axiosClient";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";
import {
  FORGOT_PASSWORD_SEND_EMAIL,
  SIGN_IN,
} from "../../constant/apiEndPoint";
import basestyle from "../Base.module.css";
import { setCurrentUser } from "../../../localStorage/setCurrentUser";

const Login = () => {
  const navigate = useNavigate();
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const handleClosePayment = () => {
    setOpenPaymentModal(false);
    formikForgot.resetForm();
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  const validationSchemaForgot = Yup.object({
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const res = await postRequest(SIGN_IN, values);
        if (res.type === 1) {
          console.log("res====>", res.response.data.data);
          Cookies.set("token", res?.response?.data?.token);
          localStorage.removeItem("current_user");
          setCurrentUser(res.response.data.data);
          successNotification(res?.response?.data?.message);
          navigate("/");
        }
        if (res.type === 0) {
          errorNotification(res.errormessage);
        }
      } catch (error) {
        console.error("res====>", error);
      }
    },
  });

  const formikForgot = useFormik({
    initialValues: {
      email: "",
    },
    validationSchemaForgot,
    onSubmit: async (values) => {
      try {
        const res = await postRequest(FORGOT_PASSWORD_SEND_EMAIL, values);
        if (res.type === 1) {
          successNotification(res?.response?.data?.message);
          navigate("/Login");
        }
        if (res.type === 0) {
          errorNotification(res.errormessage);
        }
      } catch (error) {
        console.error("res====>", error);
      }
    },
  });

  return (
    <>
      {/* Login 2 start */}
      <div className="login-2 medical-services">
        <div className="container">
          <div className="row login-box">
            <div className="col-lg-6 col-md-12 bg-img">
              <div className="info">
                <div className="info-text" style={{ textAlign: "center" }}>
                  <div className="waviy">
                    <span>welcome</span> <br />
                    <span className="color-yellow">to</span> <br />
                    <span>sage Academy</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 form-info">
              <div className="form-section">
                <div className="logo clearfix">
                  <Link to="/">
                    <img
                      src="assets/logo/Sage%20Academy%20Inverse%20Long.png"
                      alt="logo"
                    />
                  </Link>
                </div>
                <h3>Sign Into Your Account</h3>
                <div className={" login-inner-form"}>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="form-group form-box">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control"
                        placeholder="Email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <p className={basestyle.error}>{formik.errors.email}</p>
                      ) : null}
                      <i className="flaticon-mail-2" />
                    </div>
                    <div className="form-group form-box">
                      <input
                        type="password"
                        name="password"
                        id="password"
                        className="form-control"
                        autoComplete="off"
                        placeholder="Password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                      />
                      {formik.touched.password && formik.errors.password ? (
                        <p className={basestyle.error}>
                          {formik.errors.password}
                        </p>
                      ) : null}
                      <i className="flaticon-password" />
                    </div>
                    <div className="checkbox form-group form-box">
                      <span
                        onClick={() => setOpenPaymentModal(true)}
                        style={{ cursor: "pointer" }}
                      >
                        Forgot password?
                      </span>
                    </div>
                    <div className="form-group mb-0">
                      <button
                        className={
                          basestyle.button_common + " btn-md btn-theme w-100"
                        }
                        type="submit"
                        disabled={!(formik.isValid || formik.dirty)}
                      >
                        Login
                      </button>
                    </div>
                    <p className="text">
                      Don't have an account?
                      <NavLink to="/signup"> Register here</NavLink>
                    </p>
                  </form>

                  <Modal
                    show={openPaymentModal}
                    onHide={handleClosePayment}
                    backdrop="static"
                    centered
                    scrollable
                    className="ForgotPassword"
                  >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                      <div className="login-2">
                        <div className="row login-box">
                          <div className="col-lg-6 col-md-12 bg-img">
                            <div className="info">
                              <div
                                className="info-text"
                                style={{ textAlign: "center" }}
                              >
                                <div className="waviy">
                                  <span>welcome</span> <br />
                                  <span className="color-yellow">to</span>{" "}
                                  <br />
                                  <span>sage Academy</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 form-info">
                            <div className="form-section">
                              <div className="logo clearfix">
                                <Link to="/">
                                  <img
                                    src="assets/logo/Sage%20Academy%20Inverse%20Long.png"
                                    alt="logo"
                                  />
                                </Link>
                              </div>
                              <h3>Reser Your Password</h3>
                              <div className={" login-inner-form"}>
                                <form onSubmit={formikForgot.handleSubmit}>
                                  <div className="form-group form-box">
                                    <input
                                      type="email"
                                      name="email"
                                      id="email"
                                      placeholder="Email"
                                      className="form-control"
                                      onChange={formikForgot.handleChange}
                                      onBlur={formikForgot.handleBlur}
                                      value={formikForgot.values.email}
                                    />
                                    {formikForgot.touched.email &&
                                    formikForgot.errors.email ? (
                                      <p className={basestyle.error}>
                                        {formikForgot.errors.email}
                                      </p>
                                    ) : null}
                                    <i className="flaticon-mail-2" />
                                  </div>
                                  <div className="form-group mb-0">
                                    <button
                                      className={
                                        basestyle.button_common +
                                        " btn-md btn-theme w-100"
                                      }
                                      type="submit"
                                    >
                                      Send Email
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Login 2 end */}
    </>
  );
};

export default Login;
