import React from "react";
import Footer from "../../commonPages/Footer";
import Header from '../../commonPages/Header';
import Banner from './Banner';
import ConfidentialityPolicy from "./ConfidentialityPolicy";

const PrivacyPolicy = () => {
   
    return (
        <>
            {/* Main content Start */}
            <div className="main-content">
                <Header />
                <Banner />
                <ConfidentialityPolicy/>
            </div>

            <Footer />
            {/* Main content End */}
        </>
    )
}

export default PrivacyPolicy

