import { useFormik } from "formik";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import { getRequest } from "../../../helper/axiosClient";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";
import { PAYPAL_SUCCESS } from "../../constant/apiEndPoint";
import { getCurrentUserFromLocalStorage } from "../../../localStorage/setCurrentUser";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

const StudentRegisterPopup = ({
  // courseId,
  // isOpen,
  // setIsOpenPopup,
  // setCourseId,
  userId,
  courseDetails,
  // setCourseStatusForStudent,
  openPaymentModal,
  setOpenPaymentModal,
  createStudent,
  bookLoader,
}) => {
  const [loading, setLoading] = useState(false);

  // const handleClose = () => {
  //   setIsOpenPopup(false);
  //   setCourseId(null);
  //   formik.resetForm();
  // };

  function PaymentButton() {
    const [{ isPending }] = usePayPalScriptReducer();
    const paypalbuttonTransactionProps = {
      style: { layout: "vertical" },
      fundingSource: "card",
      createOrder(data, actions) {
        return actions.order.create({
          purchase_units: [
            {
              description: courseDetails._id,
              amount: {
                value: courseDetails?.price,
                currency_code: "GBP",
              },
            },
          ],
        });
      },
      onApprove(data, actions) {
        return actions.order.capture({}).then((details) => {
          // console.log("Transaction completed by " + (details?.payer.name.given_name ?? "No details"));

          paymentSuccess(data);
          // {
          //   "orderID": "5RF83688GA541335H",
          //     "payerID": "VCNAQR7VGDV9W",
          //       "paymentID": "5RF83688GA541335H",
          //         "billingToken": null,
          //           "facilitatorAccessToken": "A21AAJoGE4_vW8HtEwDZH_1OCrfL03RAd0tPLr8RHj3pHebyUqAdJYEb30gkA_ZSxrbADV7dOaoICqAm0uk_D5HbrRaUvouVQ",
          //             "paymentSource": "card"
          // }
        });
      },
      onError(err) {
        console.error("Error with PayPal:", err);
        errorNotification("Payment failed");
      },
    };
    return (
      <>
        {isPending ? <p>Load Smart Payment Button...</p> : null}
        <PayPalButtons {...paypalbuttonTransactionProps} />
      </>
    );
  }

  const paymentSuccess = async (data) => {
    setLoading(true);
    try {

      const params = {
        orderId: data.orderID,
        // token: data.facilitatorAccessToken,
        userId: userId,
      };
      const res = await getRequest(PAYPAL_SUCCESS, params);
      if (res?.data?.status === 1) {
        createStudent();
      }
      if (res.data.type === 2) {
        errorNotification(res.data.errormessage);
        setLoading(false);
      }
    } catch (error) {
      console.error("error", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // const validationSchema = Yup.object({
  //   name: Yup.string().required("Full Name is required."),
  //   email: Yup.string()
  //     .email("Invalid email address.")
  //     .required("Email is required."),
  //   phoneNumber: Yup.string()
  //     .required("Phone Number is required.")
  //     .matches(/^\d{11}$/, "Must be exactly 11 digits"),
  // });

  // const formik = useFormik({
  //   initialValues: {
  //     name: currentUser?.name,
  //     email: currentUser?.email,
  //     phoneNumber: currentUser?.phoneNumber,
  //   },
  //   validationSchema: validationSchema,
  //   onSubmit: async (values, { resetForm }) => {
  //     // if (+courseDetails?.price === 0) {
  //     //   createStudent(values, resetForm);
  //     // } else {
  //     //   setOpenPaymentModal(true);
  //     //   // paypalPayment(values);
  //     // }
  //   },
  // });

  // const createStudent = async (values, resetForm) => {
  //   try {
  //     const payload = { ...values, courseId: courseId };
  //     const res = await postRequest(CREATE_STUDENT, payload, navigate);

  //     if (res.type === 1) {
  //       setCourseStatusForStudent("Pending");
  //       successNotification(res?.response?.data?.message);
  //       setCurrentUser(res?.response?.data?.user);
  //       resetForm();
  //       // handleClose();
  //     }
  //     if (res.type === 0) {
  //       errorNotification(res.errormessage);
  //     }
  //   } catch (error) {
  //     console.error("res====>", error);
  //   }
  // };

  // const paypalPayment = async (values) => {
  //   try {
  //     const payload = {
  //       userId: currentUser?._id,
  //       currency: "USD",
  //       items: [
  //         {
  //           name: courseDetails?.name,
  //           price: courseDetails?.price,
  //           currency: "USD",
  //           quantity: 1
  //         }
  //       ]
  //     };
  //     const res = await postRequest(PAYPAL_ORDER, payload);
  //     if (res.type === 1) {
  //       window.location = res?.response?.data?.link;
  //       successNotification(res?.response?.data?.message);
  //       localStorage.setItem("payValues", JSON.stringify({ ...values, courseId: courseId }));
  //       // handleClose();
  //     }
  //     if (res.type === 0) {
  //       errorNotification(res.errormessage);
  //     }
  //   } catch (error) {
  //     console.error("res====>", error);
  //   }
  // };

  return (
    <>
      {/* <Modal
        show={isOpen}
        onHide={handleClose}
        centered
        backdrop="static"
        className="CommomForm"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>Enter Your Details</Modal.Title>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="login-2">
              <div className="row login-box">
                <div className="col-lg-12 col-md-12 form-info">
                  <div className="form-section">
                    <div className={" login-inner-form"}>
                      <div className="form-group form-box">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          id="name"
                          placeholder="Enter Your Full Name"
                          // onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div
                            className="help-block with-errors"
                            style={{ color: "#F0BD6C", fontSize: "14px", }}
                          >
                            * {formik.errors.name}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group form-box">
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          id="email"
                          placeholder="Enter Your Email"
                          // onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div
                            className="help-block with-errors"
                            style={{ color: "#F0BD6C", fontSize: "14px", }}
                          >
                            * {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group form-box">
                        <input
                          type="text"
                          name="phoneNumber"
                          className="form-control"
                          id="phoneNumber"
                          placeholder="Enter Your Phone Number"
                          // onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                          value={formik.values.phoneNumber}
                        />
                        {formik.touched.phoneNumber &&
                          formik.errors.phoneNumber ? (
                          <div
                            className="help-block with-errors"
                            style={{ color: "#F0BD6C", fontSize: "14px", }}
                          >
                            * {formik.errors.phoneNumber}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="contact-form-btn">
              <button type="submit" className="btn-default">
                Book Now
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal> */}
      <Modal
        show={openPaymentModal}
        onHide={() => setOpenPaymentModal(false)}
        backdrop="static"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>Pay Course Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(loading || bookLoader) ? <>
            <p>Please wait...</p>
            <p>Checking payment...</p>
          </> : <PaymentButton />}
          {/* <Button className="paypalBtn" onClick={() => paypalPayment()}><img src="/assets/images/paypal.svg" alt="paypal" /></Button> */}
          {/* <PayPalButton amount={courseDetails?.price} onSuccess={handleSuccess} onError={handleError} /> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StudentRegisterPopup;
