import AWS from 'aws-sdk';
const { Buffer } = require("buffer");

AWS.config.update({
  accessKeyId: "AKIAXYKJTYTDCPNB3TN3",
  secretAccessKey: "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",
});

const myBucket = new AWS.S3({
  params: { Bucket: "sageacedemy-media" },
  region: "ap-south-1",
});

const uploadPart = async (params, partNumber, partSize, media) => {
  const start = partNumber * partSize;
  const end = Math.min(start + partSize, media.file.size);

  const partParams = {
    ...params,
    Body: media.file.slice(start, end),
    PartNumber: partNumber + 1,
    UploadId: params.UploadId,
  };

  return await myBucket.uploadPart(partParams).promise();
};

const uploadMedia = async (media) => {
  const params = {
    ACL: 'private',
    Bucket: 'sageacedemy-media',
    Key: media.key,
    ContentType: media.file.type,
  };

  if (media.waterMarkedUrl) {
    const base64Data = new Buffer.from(
      media.waterMarkedUrl.replace(/^data:image\/\w+;base64,/, ''),
      'base64'
    );
    params.Body = base64Data;
    params.ContentEncoding = 'base64';

    return await myBucket.putObject(params).promise();
  } else {
    const partSize = 5 * 1024 * 1024; // 5MB
    const numParts = Math.ceil(media.file.size / partSize);

    const createMultipartParams = {
      ...params,
    };

    const multipart = await myBucket.createMultipartUpload(createMultipartParams).promise();

    params.UploadId = multipart.UploadId;

    const uploadPromises = [];
    for (let partNumber = 0; partNumber < numParts; partNumber++) {
      uploadPromises.push(uploadPart(params, partNumber, partSize, media));
    }

    const uploadedParts = await Promise.all(uploadPromises);

    const completeMultipartParams = {
      Bucket: params.Bucket,
      Key: params.Key,
      UploadId: params.UploadId,
      MultipartUpload: {
        Parts: uploadedParts.map((part, index) => ({
          ETag: part.ETag,
          PartNumber: index + 1,
        })),
      },
    };

    return await myBucket.completeMultipartUpload(completeMultipartParams).promise();
  }
};

export const uploadMediaToS3Bucket = async (mediaArray) => {
  if (mediaArray.length <= 0) {
    return false;
  }

  try {
    const uploadPromises = mediaArray.map((media) => uploadMedia(media));
    await Promise.all(uploadPromises);

    console.log('All uploads successful');
    return true; // All uploads successful
  } catch (error) {
    console.error('Error uploading images to S3:', error);
    return false; // Error occurred during upload
  }
};

export const deleteMediaFromS3Bucket = (key) => {
  const params = {
    Bucket: 'sageacedemy-media',
    Key: key,
  };

  myBucket.deleteObject(params, (err, data) => {
    if (err) {
      console.error(`Error deleting object ${key}:`, err);
    } else {
      console.log(`Object ${key} deleted successfully`);
    }
  });
};

// export const uploadMediaToS3Bucket = async (mediaArray) => {
//   if (mediaArray.length <= 0) {
//     return false;
//   }

//   try {
//     const uploadPromises = mediaArray.map(async (media) => {
//       let params = {
//         ACL: "private",
//         Bucket: "sageacedemy-media",
//         Key: media.key,
//         ContentType: media.file.type,
//       };

//       if (media.waterMarkedUrl) {
//         const base64Data = new Buffer.from(
//           media.waterMarkedUrl.replace(/^data:image\/\w+;base64,/, ""),
//           "base64"
//         );
//         params.Body = base64Data;
//         params.ContentEncoding = "base64";
//       } else {
//         params.Body = media.file;
//       }

//       await myBucket.putObject(params).promise();
//     });

//     await Promise.all(uploadPromises);
//     console.log("All uploads successful");
//     return true; // All uploads successful
//   } catch (error) {
//     console.error("Error uploading images to S3:", error);
//     return false; // Error occurred during upload
//   }
// };

// export const deleteMediaFromS3Bucket = (key) => {
//   const params = {
//     Bucket: "sageacedemy-media",
//     Key: key,
//   };

//   myBucket.deleteObject(params, (err, data) => {
//     if (err) {
//       console.error(`Error deleting object ${key}:`, err);
//     } else {
//       console.log(`Object ${key} deleted successfully`);
//     }
//   });
// };
