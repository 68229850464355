export const SIGN_UP = "users/signUp";
export const SIGN_IN = "users/signIn";
export const FORGOT_PASSWORD = "users/signIn";
export const FORGOT_PASSWORD_SEND_EMAIL = "users/signIn";
export const GET_USER = "users/getUser";
export const GET_ME = "users/getMe";

export const CREATE_CERTIFICATE = "certificate/createCertificate";

export const COURSE_LIST = "users/course/getCourse";
export const COURSE_DETAILS = "users/course/getCourseById";
export const CONTACT_POST = "contactUs/createContactUs";

export const BLOG_LIST = "users/blog/getBlog";
export const BLOG_DETAILS = "users/blog/getBlogById";

export const CREATE_STUDENT = "student/createStudent";
export const ADD_POPULAR_COURSE = "student/addPopularCourse";

export const EMAIL_SUBSCRIBE = "subscribeEmail/createSubscribeEmail"
export const PAYPAL_ORDER = "payment/createPayPalOrder"
export const PAYPAL_SUCCESS = "payment/success"