import gsap from 'gsap';
import React, { useEffect, useRef } from 'react';
import SplitType from 'split-type';
import { Link } from 'react-router-dom'
import { Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


const handleLinkClick = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};


function Whatweprovide() {
  return (
    <div className="hero-layout3 hero-slider whatweprovide">
      <div className="hero-slider-layout3">
        <Swiper
          modules={[Pagination, Navigation]}
          slidesPerView={1}
          speed={2000}
          spaceBetween={0}
          loop={true}
          autoplay={{ delay: 6000 }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
        >
          <SwiperSlide>
            <div className="hero-slide">
              {/* Slider Image Start */}
              <div className="hero-slider-image">
                <img src="assets/images/provide-1.png" alt="" />
              </div>
              {/* Slider Image End */}
              {/* Slider Content Start */}
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-6 pl-5 provide-heading">
                    {/* Hero Layout 3 Content Box Start*/}
                    <div className="hero-content headerhero">
                      <div className="section-title">
                        <h3>What we provide</h3>
                        <h1>Extensive Learning</h1>
                      </div>
                    </div>
                    {/* Hero Layout 3 Content Box End*/}
                  </div>
                  <div className="col-md-6 provide-content">
                    <div className="hero-content">
                      <div className="hero-body">
                        <p>At SAGE Academy, we offer a wide range of courses tailored to professionals at every career stage. Our commitment to excellence goes beyond traditional education, delivering dynamic learning experiences that foster growth, innovation, and career advancement.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id='whyus'></div>
              {/* Slider Content End */}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="hero-slide">
              {/* Slider Image Start */}
              <div className="hero-slider-image">
                <img src="assets/images/provide-2.png" alt="" />
              </div>
              {/* Slider Image End */}
              {/* Slider Content Start */}
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-6 provide-heading">
                    {/* Hero Layout 3 Content Box Start*/}
                    <div className="hero-content headerhero">
                      <div className="section-title">
                        <h3>What we provide</h3>
                        <h1>Flexible Scheduling</h1>
                      </div>
                    </div>
                    {/* Hero Layout 3 Content Box End*/}
                  </div>
                  <div className="col-md-6 provide-content">
                    <div className="hero-content">
                      <div className="hero-body">
                        <p>We recognize the challenge of balancing work with education, so we offer flexible scheduling for all our courses. Whether you need a short workshop or a more in-depth program, our options allow you to pursue your educational goals without compromising your professional commitments.</p>
                      </div>
                    </div>
                    {/* Hero Layout 3 Image Start */}
                    {/* <div className="hero-image">
                          <div className="hero-image-box">
                            <div className="rotate-circle" />
                            <figure>
                              <img src="assets/images/home-contact-form-bg.jpg" alt="" />
                            </figure>
                          </div>
                        </div> */}
                    {/* Hero Layout 3 Image End */}
                  </div>
                </div>
              </div>
              {/* Slider Content End */}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="hero-slide">
              {/* Slider Image Start */}
              <div className="hero-slider-image">
                <img src="assets/images/provide-3.JPG" alt="" />
              </div>
              {/* Slider Image End */}
              {/* Slider Content Start */}
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-6 provide-heading">
                    {/* Hero Layout 3 Content Box Start*/}
                    <div className="hero-content headerhero">
                      <div className="section-title">
                        <h3>What we provide</h3>
                        <h1>CPD Courses</h1>
                      </div>
                    </div>
                    {/* Hero Layout 3 Content Box End*/}
                  </div>
                  <div className="col-md-6 provide-content">
                    {/* Hero Layout 3 Image Start */}
                    {/* <div className="hero-image">
                                            <div className="hero-image-box">
                                                <div className="rotate-circle" />
                                                <figure>
                                                    <img src="assets/images/cultuer-9.jpg" alt="" />
                                                </figure>
                                            </div>
                                        </div> */}
                    <div className="hero-content">
                      <div className="hero-body">
                        <p>At SAGE, we understand the importance of CPD in your evolving career. Whether you're starting out or looking to advance your skills, our diverse CPD programs are tailored to meet your unique needs and aspirations.</p>
                      </div>
                    </div>
                    {/* Hero Layout 3 Image End */}
                  </div>
                </div>
              </div>
              {/* Slider Content End */}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="hero-slide">
              {/* Slider Image Start */}
              <div className="hero-slider-image">
                <img src="assets/images/slider-1-b.jpg" alt="" />
              </div>
              {/* Slider Image End */}
              {/* Slider Content Start */}
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-6 provide-heading">
                    {/* Hero Layout 3 Content Box Start*/}
                    <div className="hero-content headerhero">
                      <div className="section-title">
                        <h3>What we provide</h3>
                        <h1>Comprehensive Curriculum</h1>
                      </div>
                    </div>
                    {/* Hero Layout 3 Content Box End*/}
                  </div>
                  <div className="col-md-6 provide-content">
                    {/* Hero Layout 3 Image Start */}
                    {/* <div className="hero-image">
                                            <div className="hero-image-box">
                                                <div className="rotate-circle" />
                                                <figure>
                                                    <img src="assets/images/cultuer-10.jpg" alt="" />
                                                </figure>
                                            </div>
                                        </div> */}
                    <div className="hero-content">
                      <div className="hero-body">
                        <p>Our courses offer a broad range of topics and specialties, ensuring a well-rounded education. From foundational to advanced workshops, our curriculum equips you with the skills needed to excel in today's competitive environment.</p>
                      </div>
                    </div>
                    {/* Hero Layout 3 Image End */}
                  </div>
                </div>
              </div>
              {/* Slider Content End */}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="hero-slide">
              {/* Slider Image Start */}
              <div className="hero-slider-image">
                <img src="assets/images/slider-1-d.jpeg" alt="" />
              </div>
              {/* Slider Image End */}
              {/* Slider Content Start */}
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-6 provide-heading">
                    {/* Hero Layout 3 Content Box Start*/}
                    <div className="hero-content headerhero">
                      <div className="section-title">
                        <h3>What we provide</h3>
                        <h1>State-of-the-Art Facilities</h1>
                      </div>
                    </div>
                    {/* Hero Layout 3 Content Box End*/}
                  </div>
                  <div className="col-md-6 provide-content">
                    <div className="hero-content">
                      <div className="hero-body">
                        <p>Our modern facilities offer cutting-edge technology and resources for hands-on learning. With advanced training rooms and simulation centers, we equip you with the tools to excel in today's evolving industry.</p>
                      </div>
                    </div>
                    {/* Hero Layout 3 Image Start */}
                    {/* <div className="hero-image">
                                            <div className="hero-image-box">
                                                <div className="rotate-circle" />
                                                <figure>
                                                    <img src="assets/images/cultuer-8.jpg" alt="" />
                                                </figure>
                                            </div>
                                        </div> */}
                    {/* Hero Layout 3 Image End */}
                  </div>
                </div>
              </div>
              {/* Slider Content End */}
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  )
}

export default Whatweprovide