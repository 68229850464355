import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../../helper/Loader";
import { dateWithDay } from "../../../helper/dateFormate";
import CertificatePopup from "../Modal/CertificatePopup";
import StudentRegisterPopup from "../Modal/StudentRegisterPopup";
import {
  getCurrentUserFromLocalStorage,
  setCurrentUser,
} from "../../../localStorage/setCurrentUser";
import Certificate from "./Certificate";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
import { postRequest } from "../../../helper/axiosClient";
import { CREATE_STUDENT } from "../../constant/apiEndPoint";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";
// import { Spinner } from "react-bootstrap";

function Servicediffrent({ courseDetails, loading }) {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const userDetails = getCurrentUserFromLocalStorage();
  const navigate = useNavigate();
  // const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isOpenPopupC, setIsOpenPopupC] = useState(false);
  // const [courseId, setCourseId] = useState(null);
  const [courseStatusForStudent, setCourseStatusForStudent] = useState("");
  const [loader, setLoader] = useState(false);
  const [openCertificateModal, setOpenCertificateModal] = useState(false);
  const [certificateData, setCertificateData] = useState({
    courseName: courseDetails.name,
    name: "",
    gdcNumber: "",
    email: "",
    phoneNumber: "",
    address: "",
    postCode: "",
    answer1: "",
    answer2: "",
    answer3: "",
    answer4: "",
    answer5: "",
  });
  const [downloadCertificate, setDownloadCertificate] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [bookLoader, setBookLoader] = useState(false);

  const handleBook = () => {
    const token = Cookies.get("token");
    if (token) {
      // setCourseId(id);
      // setIsOpenPopup(true);

      if (+courseDetails?.price === 0) {
        createStudent();
      } else {
        setOpenPaymentModal(true);
      }
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    if (userDetails && id) {
      const courseStatus = userDetails?.courses?.find(
        (item) => item.courseId === id
      )?.status;
      setCourseStatusForStudent(courseStatus);
    }
  }, [id, userDetails]);

  const decodeHtmlEntities = (str) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = str;
    return txt.value;
  };
  const handleCertificate = () => {
    const token = Cookies.get("token");
    if (token) {
      // setCourseId(id);
      setIsOpenPopupC(true);
    } else {
      navigate("/login");
    }
  };

  const createStudent = async () => {
    setBookLoader(true);
    try {
      const payload = {
        name: userDetails?.name,
        email: userDetails?.email,
        phoneNumber: userDetails?.phoneNumber,
        courseId: id,
        courseName: courseDetails?.name,
      };
      const res = await postRequest(CREATE_STUDENT, payload, navigate);
      setBookLoader(false);

      if (res.type === 1) {
        setCourseStatusForStudent("Pending");
        if (+courseDetails?.price === 0) {
          successNotification(res?.response?.data?.message);
        } else {
          successNotification('Payment Successful');
        }
        setCurrentUser(res?.response?.data?.user);
        setOpenPaymentModal(false);
      }
      if (res.type === 0) {
        errorNotification(res.errormessage);
      }
    } catch (error) {
      setBookLoader(false);
      console.error("res====>", error);
    }
  };

  return (
    <>
      {/* Project Single Page Start */}
      {loading ? (
        <Loader />
      ) : (
        <div className="page-project-single">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                {/* Project Sidebar Start */}
                <div className="project-sidebar">
                  {/* About Project Box Start */}
                  <div className="about-project-box wow fadeInUp">
                    <h2>About the Course</h2>
                    <div className="text-center">
                      {courseDetails?.speakerImg &&
                        courseDetails?.speakerImg[0] && (
                          <span className="speaker-box">
                            <img
                              style={{
                                borderRadius: "30px",
                                objectFit: "cover",
                              }}
                              alt={courseDetails?.speakerImg[0]}
                              src={
                                process.env.REACT_APP_IMAGE_API_URL +
                                courseDetails?.speakerImg[0]
                              }
                            />
                          </span>
                        )}
                      <h3 className="my-3">{courseDetails?.speakerName}</h3>
                    </div>
                    {/* Project Info Box Start */}
                    <div className="project-info-box">
                      <div className="project-icon">
                        <img src="assets/images/icon-date.svg" alt="icon" />
                      </div>
                      <h3>START DATE:</h3>
                      <p>{dateWithDay(courseDetails?.courseStartDate)}</p>
                    </div>
                    <div className="project-info-box">
                      <div className="project-icon">
                        <img src="assets/images/icon-date.svg" alt="icon" />
                      </div>
                      <h3>END DATE:</h3>
                      <p>{dateWithDay(courseDetails?.courseEndDate)}</p>
                    </div>
                    {/* <div className="project-info-box">
                      <div className="project-icon">
                        <img src="assets/images/icon-date.svg" alt="icon" />
                      </div>
                      <h3>DURATION:</h3>
                      <p>
                        {calculateDurationInDays(
                          courseDetails?.courseStartDate,
                          courseDetails?.courseEndDate
                        )}
                        DAY
                      </p>
                    </div> */}
                    {/* Project Info Box End */}
                    {/* Project Info Box Start */}

                    {courseDetails?.cpdhours &&
                      <div className="project-info-box">
                        <div className="project-icon">
                          <img
                            src="assets/images/icon-energy.svg"
                            alt="category"
                          />
                        </div>
                        <h3>CPD HOURS:</h3>
                        <p>{courseDetails?.cpdhours || 0} HOURS</p>
                      </div>
                    }
                    <div className="project-info-box">
                      <div className="project-icon">
                        <img
                          src="assets/images/icon-energy.svg"
                          alt="category"
                        />
                      </div>
                      <h3>TIME:</h3>
                      <p>{`${courseDetails?.courseStartTime} - ${courseDetails?.courseEndTime}`}</p>
                    </div>
                    <div className="project-info-box">
                      <div className="project-icon">
                        <img
                          src="assets/images/icon-energy.svg"
                          alt="category"
                        />
                      </div>
                      <h3>Lecture:</h3>
                      <p>{courseDetails.lecture}</p>
                    </div>
                    {/* Project Info Box End */}
                    {/* Project Info Box Start */}
                    <div className="project-info-box">
                      <div className="project-icon">
                        <img src="assets/images/icon-category.svg" alt="icon" />
                      </div>
                      <h3>Category:</h3>
                      <p>{courseDetails?.category?.name}</p>
                    </div>
                    {/* Project Info Box End */}
                    {/* Project Info Box Start */}
                    <div className="project-info-box">
                      <div className="project-icon">
                        <img src="assets/images/icon-client.svg" alt="" />
                      </div>
                      <h3>Price</h3>
                      <p>£{courseDetails?.price}</p>
                    </div>
                    {/* <div className="project-info-box">
                      <div className="project-icon">
                        <img src="assets/images/icon-client.svg" alt="" />
                      </div>
                      <h3>Description</h3>
                      <p>{courseDetails?.description}</p>
                    </div> */}

                    {courseStatusForStudent === "Completed" ? (
                      <div className="d-flex justify-content-center mt-3">
                        <button
                          className="service-btn service-btncolor text-nowrap"
                          onClick={() => handleCertificate()}
                          style={{
                            cursor: "pointer",
                            color: "#7A7A6D",
                            padding: "10px 35px 10px 35px",
                          }}
                        >
                          {loader ? "Downloading..." : "Download Certificate"}
                        </button>
                      </div>
                    ) : courseStatusForStudent === "Pending" ? (
                      <div className="d-flex justify-content-center">
                        <button
                          type="submit"
                          className="btn-default text-nowrap"
                        // onClick={() => handleBook(courseId)}
                        >
                          {+courseDetails?.price === 0
                            ? "Enrolled"
                            : "Enrolled"}
                        </button>
                      </div>
                    ) : ((new Date().toISOString().split('T')[0] <= new Date(courseDetails?.courseEndDate).toISOString().split('T')[0]) &&
                      <div className="d-flex justify-content-center">
                        <button
                          type="submit"
                          className="btn-default text-nowrap"
                          onClick={() => handleBook()}
                          disabled={bookLoader}
                        >
                          {bookLoader
                            ? "Loading..."
                            : +courseDetails?.price === 0
                              ? "Register"
                              : "Book Now"}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                {/* Project Sidebar End */}
              </div>
              <div className="col-lg-8">
                {/* Project Content Start */}
                <div className="project-content">
                  {/* Project Feature Image Start */}
                  <div
                    className="project-feature-image wow fadeInUp"
                    data-wow-delay="0.25s"
                  >
                    {courseDetails?.courseVideo?.length > 0 ? (
                      <video width="300" controls={false} autoPlay muted loop>
                        <source
                          src={
                            process.env.REACT_APP_IMAGE_API_URL +
                            courseDetails?.courseVideo[0]
                          }
                        />
                        {/* <source src={video.url} type={video.file.type} /> */}
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <figure className="image-anime">
                        {courseDetails?.courseImg &&
                          courseDetails?.courseImg[0] && (
                            <img
                              alt={courseDetails?.courseImg[0]}
                              src={
                                process.env.REACT_APP_IMAGE_API_URL +
                                courseDetails?.courseImg[0]
                              }
                            />
                          )}
                      </figure>
                    )}
                  </div>
                  {/* Project Feature Image End */}
                  {/* Project Entry Start */}
                  <div className="project-entry">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: decodeHtmlEntities(
                          courseDetails?.description || ""
                        ),
                      }}
                    />
                  </div>
                </div>
                {/* Project Content End */}
              </div>
            </div>
          </div>
          {courseDetails?.doctorDetails && (
            <div className="container mt-5">
              <div className="speakerDetails project-entry wow fadeInUp">
                <h1 className="mt-0">About {courseDetails?.speakerName}</h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: decodeHtmlEntities(
                      courseDetails?.doctorDetails || ""
                    ),
                  }}
                />
              </div>
            </div>
          )}

          <StudentRegisterPopup
            courseId={id}
            userId={userDetails?._id}
            // isOpen={isOpenPopup}
            // setIsOpenPopup={setIsOpenPopup}
            // setCourseId={setCourseId}
            courseDetails={courseDetails}
            // setCourseStatusForStudent={setCourseStatusForStudent}
            openPaymentModal={openPaymentModal}
            setOpenPaymentModal={setOpenPaymentModal}
            createStudent={createStudent}
            bookLoader={bookLoader}
          />
          <CertificatePopup
            // courseId={courseId}
            isOpen={isOpenPopupC}
            setIsOpenPopup={setIsOpenPopupC}
            // setCourseId={setCourseId}
            certificateData={certificateData}
            setCertificateData={setCertificateData}
            setDownloadCertificate={setDownloadCertificate}
            downloadCertificate={downloadCertificate}
            openCertificateModal={openCertificateModal}
            setOpenCertificateModal={setOpenCertificateModal}
            loader={loader}
            setLoader={setLoader}
          />

          <Certificate
            loader={loader}
            setLoader={setLoader}
            courseDetails={courseDetails}
            certificateData={certificateData}
            setCertificateData={setCertificateData}
            setDownloadCertificate={setDownloadCertificate}
            downloadCertificate={downloadCertificate}
            openCertificateModal={openCertificateModal}
            setOpenCertificateModal={setOpenCertificateModal}
            userDetails={userDetails}
          />
        </div>
      )}
      {/* Project Single Page End */}
    </>
  );
}

export default Servicediffrent;
