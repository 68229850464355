import React, { useEffect, useState } from "react";
import "./Certificate.css"; // Assuming the CSS is in this file
import CertificateBgImage from "./CertificateBGImage.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import { uploadMediaToS3Bucket } from "../../../helper/awsHelper";
import { postFormRequest } from "../../../helper/axiosClient";
import { CREATE_CERTIFICATE } from "../../constant/apiEndPoint";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";
import { dateWithDay } from "../../../helper/dateFormate";

const Certificate = ({
  loader,
  setLoader,
  courseDetails,
  certificateData,
  setCertificateData,
  downloadCertificate,
  setDownloadCertificate,
  openCertificateModal,
  setOpenCertificateModal,
  userDetails,
}) => {
  let certificateFile = [];

  const decodeHtmlEntities = (str) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = str;
    return txt.value;
  };

  const generatePage1 = async () => {
    const input1 = document.getElementById("page1");

    await new Promise((resolve) => {
      const img = new Image();
      img.src = CertificateBgImage;
      img.onload = resolve;
    });

    const canvas1 = await html2canvas(input1, {
      scale: 2,
      useCORS: true,
      logging: true,
    });

    return canvas1.toDataURL("image/png");
  };

  const generatePage2 = async () => {
    const input2 = document.getElementById("page2");

    const canvas2 = await html2canvas(input2, {
      scale: 2,
      useCORS: true,
      logging: true,
    });

    return canvas2.toDataURL("image/png");
  };

  const generatePdf = async () => {
    setLoader(true);
    try {
      const imgData1 = await generatePage1();
      const imgData2 = await generatePage2();

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: "a4",
      });

      const canvasWidth = pdf.internal.pageSize.getWidth();
      const canvasHeight = pdf.internal.pageSize.getHeight();

      pdf.addImage(imgData1, "PNG", 0, 0, canvasWidth, canvasHeight);
      pdf.addPage();
      pdf.addImage(imgData2, "PNG", 0, 0, canvasWidth, canvasHeight);
      pdf.save(`${certificateData.name}_${courseDetails.name}.pdf`);

      const pdfBlob = pdf.output("blob");
      const pdfFile = new File(
        [pdfBlob],
        `${certificateData.name}_${courseDetails.name}.pdf`,
        {
          type: "application/pdf",
        }
      );
      // setDownloadCertificate(false);
      // setLoader(false);
      // setOpenCertificateModal(false);
      return pdfFile;
    } catch (error) {
      console.error("Error generating PDF: ", error);
      setLoader(false);
      throw error;
    }
  };

  const uploadImagesAndPdf = async () => {
    try {
      // Step 1: Generate the PDF
      const pdfFile = await generatePdf();

      // Step 2: Add the PDF file to the speakerImage array
      certificateFile.push({ file: pdfFile });

      // Step 3: Process and upload images

      let forS3MediaArray = [];

      certificateFile = await Promise.all(
        certificateFile.map(async (image) => {
          if (image.file) {
            let nameArray = image.file.name.split(".");
            let key = `${nameArray[0]}_${Date.now()}.${nameArray[nameArray.length - 1]}`;
            // let waterMarkedUrl = await applyBottomAndCenterWatermark(
            //   image.file
            // );
            forS3MediaArray.push({ ...image, key: key });
            return { ...image, key: key };
          }
        })
      );

      console.log({ certificateFile })

      // Upload new images to S3
      await uploadMediaToS3Bucket([...forS3MediaArray]);
      const payload = {
        ...certificateData,
        course: courseDetails._id,
        user: userDetails._id,
        certificateFile: certificateFile.map((item) => item.key),
      };
      console.log({ payload })
      try {
        const res = await postFormRequest(CREATE_CERTIFICATE, payload);
        if (res.type === 1) {
          successNotification(res?.response?.data?.message);
        }
        if (res.type === 2) {
          errorNotification(res.errormessage);
        }
      } catch (error) {
        console.log("res====>", error);
      } finally {
        setDownloadCertificate(false);
        setLoader(false);
        setOpenCertificateModal(false);
      }
    } catch (error) {
      console.error("Error during image upload: ", error);
    }
  };

  useEffect(() => {
    if (downloadCertificate && certificateData) {
      uploadImagesAndPdf();
    }
  }, [certificateData]);

  return (
    <>
      <div
        id="page1"
        style={{
          width: "908px",
          height: "1287px",
          backgroundColor: "white",
          display: downloadCertificate ? "block" : "none",
        }}
      >
        <div
          id="p1"
          style={{
            overflow: "hidden",
            position: "relative",
            backgroundColor: "white",
            width: "908px",
            height: "1287px",
          }}
        >
          {/* Background Image */}
          <div
            id="pg1Overlay"
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: 1,
              backgroundColor: "rgba(0,0,0,0)",
            }}
          ></div>
          <div id="pg1" style={{ userSelect: "none" }}>
            <img alt="CertificateBgImage" src={CertificateBgImage}></img>
          </div>

          {/* Text Content */}
          <div className="text-container">
            <div className="certificateDetails">
              <h3>Certificate of Attendance</h3>
              <p className="font1">This is to clarify that</p>
              <p className="dark-text font2">{certificateData.name}</p>
              {certificateData.gdcNumber && (
                <p className="font1">GDC No: {certificateData.gdcNumber}</p>
              )}
              {/* <p className="font1">GDC No: {certificateData.gdcNumber}</p> */}
              <p className="font1">Attended a training session on the topic of:</p>
              <p className="dark-text font2">{courseDetails?.name}</p>
              <p className="dark-text font2">On {dateWithDay(courseDetails?.courseStartDate)}</p>
              <p className="dark-text font2">({courseDetails?.cpdhours} HOURS VARIFIABLE CPD)</p>

              <div className="aimsDiv">
                <div className="mb-2">
                  <h6>Aims :</h6>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: decodeHtmlEntities(
                        courseDetails?.aims || ""
                      ),
                    }}
                  />
                </div>
                <div className="mb-2">
                  <h6>Objectives :</h6>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: decodeHtmlEntities(
                        courseDetails?.objectives || ""
                      ),
                    }}
                  />
                </div>
                <div className="mb-2">
                  <h6>Learning Content :</h6>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: decodeHtmlEntities(
                        courseDetails?.learningContent || ""
                      ),
                    }}
                  />
                </div>
                <div className="mb-2">
                  <h6>Development Outcomes :</h6>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: decodeHtmlEntities(
                        courseDetails?.developmentOutcome || ""
                      ),
                    }}
                  />
                </div>
              </div>

              <div className="declairDiv">We confirm that the information provided on this certificate is full and accurate.</div>

              <h5>This CPD is subject to quality assurance by {courseDetails?.speakerName}</h5>
            </div>
          </div>
          {/* <div className="text-container">
            <span id="t1_1" className="t s0">
              Certificate of attendance{" "}
            </span>
            <span id="t2_1" className="t s1">
              Development outcomes:{" "}
            </span>
            <span id="t3_1" className="t s2">
              This CPD course meets the criteria for the{" "}
            </span>
            <span id="t4_1" className="t s3">
              GDC’s{" "}
            </span>
            <span id="t5_1" className="t s2">
              development outcomes B.{" "}
            </span>
            <span id="t6_1" className="t s2">
              We confirm that the information provided on{" "}
            </span>
            <span id="t7_1" className="t s2">
              this certificate is full and accurate.{" "}
            </span>
            <span id="t8_1" className="t s4">
              This CPD is subject to quality assurance by {courseDetails?.speakerName}
            </span>
            <span id="t9_1" className="t s5">
              This is to certify that{" "}
            </span>
            <span
              id="ta_1"
              className="t s4"
              style={{ width: "100%", textAlign: "center" }}
            >
              {certificateData.name}{" "}
            </span>
            <span
              id="tb_1"
              className="t s5"
              style={{ width: "100%", textAlign: "center" }}
            >
              GDC No: {certificateData.gdcNumber}{" "}
            </span>
            <span id="tc_1" className="t s5">
              Attended a training session on the topic of:{" "}
            </span>
            <span id="td_1" className="t s4">
              {courseDetails?.name}{" "}
            </span>
            <span id="te_1" className="t s6">
              on 19 th June{" "}
            </span>
            <span id="tf_1" className="t s6">
              (1 HOURS VERIFIABLE CPD){" "}
            </span>
            <span id="tg_1" className="t s6">
              Aims:{" "}
            </span>
            <span id="th_1" className="t s7">
              This program aims to explore how the interaction between
              orthodontics and other dental{" "}
            </span>
            <span id="ti_1" className="t s7">
              specialties can lead to excellent outcomes in cases involving
              trauma to central incisors and{" "}
            </span>
            <span id="tj_1" className="t s7">
              missing lateral incisors.{" "}
            </span>
            <span id="tk_1" className="t s6">
              Objectives:{" "}
            </span>
            <span id="tl_1" className="t s7">
              1.A deeper understanding{" "}
            </span>
            <span id="tm_1" className="t s7">
              of the synergies{" "}
            </span>
            <span id="tn_1" className="t s7">
              between orthodontics and other dental specialties.{" "}
            </span>
            <span id="to_1" className="t s6">
              Learning content:{" "}
            </span>
            <span id="tp_1" className="t s2">
              How Other Specialties Enhance Orthodontic Outcomes in Trauma and
              Missing Incisors{" "}
            </span>
            <span id="tq_1" className="t s2">
              1.Advanced Techniques: Key interdisciplinary approaches and
              techniques for managing and{" "}
            </span>
            <span id="tr_1" className="t s2">
              treating trauma and missing central and lateral incisors.{" "}
            </span>
          </div> */}
        </div>
      </div>
      {/* //Page 2 */}
      <div
        id="page2"
        style={{
          width: "908px",
          height: "1287px",
          backgroundColor: "white",
          padding: "32px",
          display: downloadCertificate ? "block" : "none",
        }}
      >
        <div
          style={{
            overflow: "hidden",

            backgroundColor: "white",
            height: "1287px",
          }}
        >
          <h3 className="reflective-heading">Reflective Practice</h3>
          <div className="reflective-subheading">
            {`Course : ` +
              courseDetails.name +
              `     |      Date: ${moment(courseDetails.courseEndDate).format("DD/MM/YYYY")}`}
          </div>
          <div className="faq-list">
            <div className="faq">
              <div className="faq-header">
                <h3 className="faq-title">
                  What did you learn from this course?
                </h3>
              </div>
              <div className="faq-body">
                <div className="faq-text">{certificateData.answer1}</div>
              </div>
            </div>
            <div className="faq">
              <div className="faq-header">
                <h3 className="faq-title">
                  How will you apply the knowledge you have learned how valuable
                  was this course in your profession? to your profession?
                </h3>
              </div>
              <div className="faq-body">
                <div className="faq-text">{certificateData.answer2}</div>
              </div>
            </div>
            <div className="faq">
              <div className="faq-header">
                <h3 className="faq-title">
                  How do you intend to maintain the knowledge you have learned
                  for the future?
                </h3>
              </div>
              <div className="faq-body">
                <div className="faq-text">{certificateData.answer3}</div>
              </div>
            </div>
            <div className="faq">
              <div className="faq-header">
                <h3 className="faq-title">
                  How valuable was this course in your profession?
                </h3>
              </div>
              <div className="faq-body">
                <div className="faq-text">{certificateData.answer4}</div>
              </div>
            </div>
            <div className="faq">
              <div className="faq-header">
                <h3 className="faq-title">
                  How will this new knowledge impact on your current job role?
                </h3>
              </div>
              <div className="faq-body">
                <div className="faq-text">{certificateData.answer5}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Certificate;
