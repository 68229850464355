// import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { useFormik } from "formik";
import React from "react";
import { Modal } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { getCurrentUserFromLocalStorage } from "../../../localStorage/setCurrentUser";
// import { postRequest } from "../../../helper/axiosClient";
// import { errorNotification, successNotification, } from "../../../helper/notification";
// import { CREATE_STUDENT } from "../../constant/apiEndPoint";

const CertificatePopup = ({
  loader,
  // courseId,
  isOpen,
  setIsOpenPopup,
  // setCourseId,
  certificateData,
  setCertificateData,
  setDownloadCertificate,
  openCertificateModal,
  setOpenCertificateModal,
}) => {

  const userDetails = getCurrentUserFromLocalStorage();

  const validationSchema = Yup.object({
    name: Yup.string().required("Full Name is required."),
    // gdcNumber: Yup.string().required("GDC Number is required."),
    email: Yup.string()
      .email("Invalid email address.")
      .required("Email is required."),
    phoneNumber: Yup.string()
      .required("Phone Number is required.")
      .matches(/^\d{11}$/, "Must be exactly 11 digits"),
    address: Yup.string().required("address Number is required."),
    postCode: Yup.string().required("postCode Number is required."),
  });

  const validationSchema2 = Yup.object({
    answer1: Yup.string()
      .max(600, 'Must be 600 characters or less'),
    answer2: Yup.string()
      .max(600, 'Must be 600 characters or less'),
    answer3: Yup.string()
      .max(600, 'Must be 600 characters or less'),
    answer4: Yup.string()
      .max(600, 'Must be 600 characters or less'),
    answer5: Yup.string()
      .max(600, 'Must be 600 characters or less'),
    // .required('This field is required'),
  });

  const formik1 = useFormik({
    initialValues: {
      name: userDetails?.name,
      gdcNumber: "",
      email: userDetails?.email,
      phoneNumber: userDetails?.phoneNumber,
      address: "",
      postCode: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setCertificateData({ ...certificateData, ...values });
      setOpenCertificateModal(true);
      setIsOpenPopup(false);
      formik1.resetForm();
    },
  });
  const formik2 = useFormik({
    initialValues: {
      answer1: "",
      answer2: "",
      answer3: "",
      answer4: "",
      answer5: "",
    },
    validationSchema: validationSchema2,
    onSubmit: async (values, { resetForm }) => {
      setCertificateData({ ...certificateData, ...values });
      setDownloadCertificate(true);
      formik2.resetForm();
    },
  });

  return (
    <>
      <Modal
        show={isOpen}
        onHide={() => {
          setIsOpenPopup(false);
          // setCourseId(null);
        }}
        centered
        backdrop="static"
        className="CommomForm"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>For the Registration</Modal.Title>
        </Modal.Header>
        <form onSubmit={formik1.handleSubmit}>
          <Modal.Body>
            <div className="login-2">
              <div className="row login-box">
                <div className="col-lg-12 col-md-12 form-info">
                  <div className="form-section">
                    <div className={" login-inner-form"}>
                      {/* <div className="form-group form-box">
                        <input
                          required
                          type="text"
                          name="name"
                          className="form-control"
                          id="name"
                          placeholder="Enter Your Name"
                          onChange={formik1.handleChange}
                          onBlur={formik1.handleBlur}
                          value={formik1.values.name}
                        />
                        {formik1.touched.name && formik1.errors.name ? (
                          <div
                            className="help-block with-errors"
                            style={{ color: "#F0BD6C", fontSize: "14px", }}
                          >
                            * {formik1.errors.name}
                          </div>
                        ) : null}
                      </div> */}
                      <div className="form-group form-box">
                        <input
                          type="number"
                          name="gdcNumber"
                          className="form-control"
                          id="gdcNumber"
                          placeholder="GDC Number"
                          onChange={formik1.handleChange}
                          onBlur={formik1.handleBlur}
                          value={formik1.values.gdcNumber}
                        />
                        {formik1.touched.gdcNumber &&
                          formik1.errors.gdcNumber ? (
                          <div
                            className="help-block with-errors"
                            style={{ color: "#F0BD6C", fontSize: "14px", }}
                          >
                            * {formik1.errors.gdcNumber}
                          </div>
                        ) : null}
                      </div>
                      {/* <div className="form-group form-box">
                        <input
                          required
                          type="email"
                          name="email"
                          className="form-control"
                          id="email"
                          placeholder="Enter Your Email"
                          onChange={formik1.handleChange}
                          onBlur={formik1.handleBlur}
                          value={formik1.values.email}
                        />
                        {formik1.touched.email && formik1.errors.email ? (
                          <div
                            className="help-block with-errors"
                            style={{ color: "#F0BD6C", fontSize: "14px", }}
                          >
                            * {formik1.errors.email}
                          </div>
                        ) : null}
                      </div> */}
                      {/* <div className="form-group form-box">
                        <input
                          required
                          type="text"
                          name="phoneNumber"
                          className="form-control"
                          id="phoneNumber"
                          placeholder="Enter Your Phone Number"
                          onChange={formik1.handleChange}
                          onBlur={formik1.handleBlur}
                          value={formik1.values.phoneNumber}
                        />
                        {formik1.touched.phoneNumber &&
                          formik1.errors.phoneNumber ? (
                          <div
                            className="help-block with-errors"
                            style={{ color: "#F0BD6C", fontSize: "14px", }}
                          >
                            * {formik1.errors.phoneNumber}
                          </div>
                        ) : null}
                      </div> */}
                      <div className="form-group form-box">
                        <input
                          required
                          type="text"
                          name="address"
                          className="form-control"
                          id="address"
                          placeholder="Enter Your Adress"
                          onChange={formik1.handleChange}
                          onBlur={formik1.handleBlur}
                          value={formik1.values.address}
                        />
                        {formik1.touched.address && formik1.errors.address ? (
                          <div
                            className="help-block with-errors"
                            style={{ color: "#F0BD6C", fontSize: "14px", }}
                          >
                            * {formik1.errors.address}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group form-box">
                        <input
                          required
                          type="text"
                          name="postCode"
                          className="form-control"
                          id="postCode"
                          placeholder="Enter Your PostCode"
                          onChange={formik1.handleChange}
                          onBlur={formik1.handleBlur}
                          value={formik1.values.postCode}
                        />
                        {formik1.touched.postCode && formik1.errors.postCode ? (
                          <div
                            className="help-block with-errors"
                            style={{ color: "#F0BD6C", fontSize: "14px", }}
                          >
                            * {formik1.errors.postCode}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="contact-form-btn">
              <button type="submit" className="btn-default">
                Next
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal
        show={openCertificateModal}
        onHide={() => setOpenCertificateModal(false)}
        backdrop="static"
        className="CommomForm"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>Feedback form questions</Modal.Title>
        </Modal.Header>

        <form onSubmit={formik2.handleSubmit} style={{ overflowY: "scroll" }}>
          <Modal.Body>
            <div className="login-2">
              <div className="row login-box">
                <div className="col-lg-12 col-md-12 form-info">
                  <div className="form-section">
                    <div className={" login-inner-form"}>
                      <div className="form-group form-box">
                        <label className="">
                          What did you learn from this course?
                        </label>
                        <textarea
                          name="answer1"
                          className="form-control"
                          id="answer1"
                          rows={4}
                          // maxLength={450}
                          placeholder="Type Your Message"
                          onChange={formik2.handleChange}
                          onBlur={formik2.handleBlur}
                          value={formik2.values.answer1}
                        />
                        {formik2.touched.answer1 && formik2.errors.answer1 ? (
                          <div
                            className="help-block with-errors"
                            style={{ color: "#F0BD6C", fontSize: "14px", }}
                          >
                            {formik2.errors.answer1}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group form-box">
                        <label className="">
                          How will you apply the knowledge you have learned how
                          valuable was this course in your profession? to your
                          profession?
                        </label>
                        <textarea
                          name="answer2"
                          className="form-control"
                          id="answer2"
                          rows={4}
                          placeholder="Type Your Message"
                          onChange={formik2.handleChange}
                          onBlur={formik2.handleBlur}
                          value={formik2.values.answer2}
                        />
                        {formik2.touched.answer2 && formik2.errors.answer2 ? (
                          <div
                            className="help-block with-errors"
                            style={{ color: "#F0BD6C", fontSize: "14px", }}
                          >
                            {formik2.errors.answer2}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group form-box">
                        <label className="">
                          How do you intend to maintain the knowledge you have
                          learned for the future?
                        </label>
                        <textarea
                          name="answer3"
                          className="form-control"
                          id="answer3"
                          rows={4}
                          placeholder="Type Your Message"
                          onChange={formik2.handleChange}
                          onBlur={formik2.handleBlur}
                          value={formik2.values.answer3}
                        />
                        {formik2.touched.answer3 && formik2.errors.answer3 ? (
                          <div
                            className="help-block with-errors"
                            style={{ color: "#F0BD6C", fontSize: "14px", }}
                          >
                            {formik2.errors.answer3}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group form-box">
                        <label className="">
                          How valuable was this course in your profession?
                        </label>
                        <textarea
                          name="answer4"
                          className="form-control"
                          id="answer4"
                          rows={4}
                          placeholder="Type Your Message"
                          onChange={formik2.handleChange}
                          onBlur={formik2.handleBlur}
                          value={formik2.values.answer4}
                        />
                        {formik2.touched.answer4 && formik2.errors.answer4 ? (
                          <div
                            className="help-block with-errors"
                            style={{ color: "#F0BD6C", fontSize: "14px", }}
                          >
                            {formik2.errors.answer4}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group form-box">
                        <label className="">
                          How will this new knowledge impact on your current job
                          role?
                        </label>
                        <textarea
                          name="answer5"
                          className="form-control"
                          id="answer5"
                          rows={4}
                          placeholder="Type Your Message"
                          onChange={formik2.handleChange}
                          onBlur={formik2.handleBlur}
                          value={formik2.values.answer5}
                        />
                        {formik2.touched.answer5 && formik2.errors.answer5 ? (
                          <div
                            className="help-block with-errors"
                            style={{ color: "#F0BD6C", fontSize: "14px", }}
                          >
                            {formik2.errors.answer5}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="contact-form-btn">
              <button type="submit" className="btn-default">
                {loader ? "Downloading..." : "Submit"}
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default CertificatePopup;
