import { useFormik } from "formik";
import gsap from 'gsap';
import React, { useEffect, useRef } from 'react';
import SplitType from 'split-type';
import * as Yup from "yup";
import { postRequest } from "../../../helper/axiosClient";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";
import { CONTACT_POST } from "../../constant/apiEndPoint";

const validationSchema = Yup.object({
  name: Yup.string().required("Full Name is required."),
  email: Yup.string()
    .email("Invalid email address.")
    .required("Email is required."),
  phoneNumber: Yup.string()
    .matches(/^\d{11}$/, "Invalid Phone Number")
    .required("Phone Number is required."),
  comment: Yup.string().required("Message is required."),
});

function Contact() {

  const elementRef = useRef(null);

  useEffect(() => {
    if (elementRef.current) {
      const element = elementRef.current;

      if (element.animation) {
        element.animation.progress(1).kill();
      }

      let split = new SplitType(element, { types: 'lines,words,chars' });
      gsap.set(element, { perspective: 400 });

      gsap.set(split.chars, {
        opacity: 0,
        x: "50",
      });

      element.animation = gsap.to(split.chars, {
        scrollTrigger: { trigger: element, start: "top 90%" },
        x: "0",
        y: "0",
        rotateX: "0",
        opacity: 1,
        duration: 1,
        ease: "back.out",
        stagger: 0.02,
      });
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      comment: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // Handle form submission
      try {
        const res = await postRequest(CONTACT_POST, values);
        if (res.type === 1) {
          successNotification(res?.response?.data?.message);
          resetForm();
        }
        if (res.type === 0) {
          errorNotification(res.errormessage);
        }
      } catch (error) {
        console.error("res====>", error);
      }
      // Handle form submission here
    },
  });

  return (
    <>
      {/* Footer Contact Form Start  */}
      <div className="home-contact-form parallaxie contactformbg" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/* Section Title Start */}
              <div className="section-title">
                <h3 className="wow fadeInUp">fill the form</h3>
                <h2 className="text-anime-style-3" ref={elementRef}>Contact Form</h2>
              </div>
              {/* Section Title End */}
            </div>
          </div>
          <div className="row no-gutters wow fadeInUp" data-wow-delay="0.25s">
            <div className="col-lg-4 col-md-5 col-12">
              {/* Contact Appointment Start */}
              <div className="contact-appointment">
                <div className="appointment-img">
                  <img src="assets/images/Pics/img.jpeg" alt="" />
                </div>
                {/* <div className="appointment-content">
                  <h3>
                    Make <strong>Appointment </strong>&amp; Take Care Of Your
                    Healthy Life
                  </h3>
                </div> */}
              </div>
              {/* Contact Appointment End */}
            </div>
            <div className="col-lg-8 col-md-7 col-12">
              {/* Contact Form start */}
              <div className="contact-form">
                <form onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="form-group col-md-6 mb-4">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Enter Your Full Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <div
                          className="help-block with-errors"
                          style={{ color: "#F0BD6C", fontSize: "14px" }}
                        >
                          * {formik.errors.name}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6 mb-4">
                      <input
                        type="text"
                        name="phoneNumber"
                        className="form-control"
                        id="phoneNumber"
                        placeholder="Enter Your Phone Number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phoneNumber}
                      />
                      {formik.touched.phoneNumber &&
                        formik.errors.phoneNumber ? (
                        <div
                          className="help-block with-errors"
                          style={{ color: "#F0BD6C", fontSize: "14px" }}
                        >
                          * {formik.errors.phoneNumber}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-12 mb-4">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter Your Email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div
                          className="help-block with-errors"
                          style={{ color: "#F0BD6C", fontSize: "14px" }}
                        >
                          * {formik.errors.email}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-12 mb-4">
                      <textarea
                        name="comment"
                        className="form-control"
                        id="comment"
                        rows={4}
                        placeholder="Type Your Message"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.comment}
                      />
                      {formik.touched.comment && formik.errors.comment ? (
                        <div
                          className="help-block with-errors"
                          style={{ color: "#F0BD6C", fontSize: "14px" }}
                        >
                          {formik.errors.comment}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-lg-12">
                      <div className="contact-form-btn">
                        <button type="submit" className="btn-default">
                          Contact us
                        </button>
                        <div id="msgSubmit" className="h3 hidden" />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {/* Contact Form end */}
            </div>
          </div>
        </div>
      </div>
      {/* Footer Contact Form End */}
    </>

  );
}

export default Contact;
